import { useStorage } from "@vueuse/core";

const defaultState = () => {
  return {
    bookingDuration: useStorage("bookingDuration", 0, sessionStorage),
    option: useStorage("option", "nominate", sessionStorage),
    optionRequire: useStorage("optionRequire", false, sessionStorage),
    partner: useStorage("partner", "", sessionStorage),
    autoStaffDistribution: useStorage("autoStaffDistribution", false, sessionStorage),
    showButtonWithoutStaff: useStorage("showButtonWithoutStaff", true, sessionStorage),
  };
};

const BookingSetting = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setBookingSetting(state: any, setting: any) {
      state.bookingDuration = setting.booking_duration;
      state.option = setting.option;
      state.optionRequire = setting.option_require;
      state.partner = setting.partner;
      state.autoStaffDistribution = setting.auto_staff_distribution;
      state.showButtonWithoutStaff = setting.show_button_without_staff;
      state.partner = setting.partner;
    },
    setAutoStaffDistribution(state: any, autoStaffDistribution: boolean) {
      state.autoStaffDistribution = autoStaffDistribution;
    },
  },
  getters: {
    settings: (state: any) => {
      return state;
    },
    bookingDuration: (state: any) => {
      return state.bookingDuration;
    },
    option: (state: any) => {
      return state.option;
    },
    optionRequire: (state: any) => {
      return state.optionRequire;
    },
    partner: (state: any) => {
      return state.partner;
    },
    autoStaffDistribution: (state: any) => {
      return state.autoStaffDistribution;
    },
    showButtonWithoutStaff: (state: any) => {
      return state.showButtonWithoutStaff;
    },
  },
};

export default BookingSetting;
