import RouterConstants from "@/common/constants/app.constants";

export default class ApiRouterConstants {
  static API_LINE_ACCOUNTS = `${RouterConstants.API_URL}api/v1/customers/lines/accounts`;
  static API_BOOKING_SETTING = `${RouterConstants.API_URL}api/v1/customers/booking_setting`;
  static API_CLINIC_DETAILS = `${RouterConstants.API_URL}api/v1/customers/clinic_details`;
  static API_MEDICAL_MENU_CATEGORIES = `${RouterConstants.API_URL}api/v1/customers/medical_menu_categories`;
  static API_ROOMS = `${RouterConstants.API_URL}api/v1/customers/rooms`;
  static API_SERVICE_TAGS = `${RouterConstants.API_URL}api/v1/customers/service_tags`;
  static API_CALENDARS_EVENTS = `${RouterConstants.API_URL}api/v1/customers/calendars/events`;
  static API_OTP = `${RouterConstants.API_URL}api/v1/customers/otp`;
}
