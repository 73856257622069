const calenderSlotInterval = "00:20:00";

enum BookingViewEnum {
  Day = "日",
  Week = "週",
  // Month = "月",
}

enum BookingViewTypeEnum {
  Day = "Day",
  Week = "Week",
  // Month = "Month",
}

enum BookingViewPageTypeEnum {
  List = "List",
  RoomCalendar = "RoomCalendar",
  StaffCalendar = "StaffCalendar",
}

enum BookingResourceId {
  Room = "room_id",
  Staff = "staff_id",
}

enum BookingResourceFieldNameEnum {
  Day = "staffId",
  Week = "staffId",
}

type BookingViewTypeSlotDurationEnumType = {
  Day: string;
  Week: string;
};

const BookingViewTypeSlotDurationEnum: BookingViewTypeSlotDurationEnumType = {
  Day: calenderSlotInterval,
  Week: "24:00",
};

type IBookingOption = {
  name: string;
  value: string;
};

type IBookingColorOption = {
  name: string;
  value: string;
  colorClass: string;
};

const BookingViewOptions: IBookingOption[] = Object.entries(BookingViewEnum).map(([value, name]) => ({
  name,
  value,
}));

const BookingStatusFull = "すべて表示";

enum BookingStatusEnum {
  will_come = "未来店",
  booked = "受付済",
  completed = "会計済",
  visited = "来店済",
  cancel = "患者キャンセル",
  cancel_not_notice = "無断キャンセル",
}

enum FullBookingStatusEnum {
  full = "すべて表示",
  will_come = "未来店",
  visited = "来店済",
  cancel = "患者キャンセル",
  cancel_not_notice = "無断キャンセル",
}

enum DistributionGroupBookingStatusEnum {
  full = "すべて表示",
  will_come = "未来店",
  visited = "来店済",
  cancel = "キャンセル",
  cancel_not_notice = "無断キャンセル",
}

const BookingStatusColorClass = {
  will_come: "bg-will-come",
  cancel: "bg-cancel",
  cancel_not_notice: "bg-cancel-not-notice",
  booked: "bg-booked",
  booking_request: "bg-booking-request",
  completed_visited: "bg-visited",
  visited: "bg-visited",
  completed: "bg-visited",
};

const BookingSourceColorClasses = {
  hotpepper: "bg-hotpepper",
};

const BookingSecondStatus = {
  will_come: "予約",
  cancel: "患者キャンセル",
  cancel_not_notice: "無断キャンセル",
  booking_request: "リクエスト予約",
};

enum BookingStatusSearchEnum {
  default = "-- 選ぶ --",
  will_come = "未来店",
  visited = "来店済",
  cancel = "患者キャンセル",
  cancel_not_notice = "無断キャンセル",
}
enum BookingMenuSearchEnum {
  id = 0,
  name = "-- 選ぶ --",
}

const inactiveBookingStatus = ["booked", "completed"];

const BookingStatusOptions: IBookingOption[] = Object.entries(BookingStatusEnum)
  .filter(([key]) => !inactiveBookingStatus.includes(key))
  .map(([value, name]) => ({
    name,
    value,
  }));

const FullBookingStatusOptions: IBookingOption[] = Object.entries(FullBookingStatusEnum).map(([value, name]) => ({
  name,
  value,
}));

const DistributionGroupBookingStatusOptions: IBookingOption[] = Object.entries(DistributionGroupBookingStatusEnum).map(
  ([value, name]) => ({
    name,
    value,
  })
);

const BookingStatusColorOptions: IBookingColorOption[] = Object.entries(BookingSecondStatus).map(([value, name]) => ({
  name,
  value,
  colorClass: BookingStatusColorClass[value as keyof typeof BookingStatusColorClass],
}));

const BookingStatusSearchOptions: IBookingOption[] = Object.entries(BookingStatusSearchEnum).map(([value, name]) => ({
  name,
  value,
}));

const BookingTimeOptions: IBookingOption[] = Array.from({ length: 24 }, (_, i) => {
  const time = `${i.toString().padStart(2, "0")}:00`;
  return { name: time, value: time };
});

const BookingHourOptions: IBookingOption[] = Array.from({ length: 24 }, (_, i) => {
  const time = `${i.toString().padStart(2, "0")}`;
  return { name: time + "時", value: time };
});

const BookingMinuteOptions: IBookingOption[] = Array.from({ length: 12 }, (_, i) => {
  const time = `${(i * 5).toString().padStart(2, "0")}`;
  return { name: time + "分", value: time };
});

enum BookingCustomerStatus {
  new = "新規",
  existed = "既存",
}

enum BookingActionEnum {
  new = "NewBooking",
  show = "ShowBooking",
  edit = "EditBooking",
}

enum BookingViewTypeDaysEnum {
  day = 1,
  week = 7,
}

export {
  BookingViewEnum,
  BookingViewTypeEnum,
  BookingResourceFieldNameEnum,
  BookingResourceId,
  BookingViewTypeDaysEnum,
  BookingViewTypeSlotDurationEnum,
  BookingViewOptions,
  BookingStatusFull,
  BookingStatusEnum,
  BookingStatusOptions,
  BookingStatusColorOptions,
  BookingTimeOptions,
  BookingHourOptions,
  BookingMinuteOptions,
  BookingCustomerStatus,
  BookingActionEnum,
  BookingStatusColorClass,
  BookingSourceColorClasses,
  BookingViewPageTypeEnum,
  BookingStatusSearchOptions,
  FullBookingStatusOptions,
  BookingMenuSearchEnum,
  DistributionGroupBookingStatusOptions,
};
